@import "settings_override";
footer {
    background: #263947;
    position: relative;
    flex: 0 0 auto;
    .footer-col {
        padding-right: rem-calc(60);
        padding-bottom: rem-calc(30);
        &:LAST-CHILD {
        }
    }

    .footer-wrapper {
        max-width: $row-width;
        margin: auto;
        padding: rem-calc(40px 40px 60px);
    }

    @media (max-width: $small-breakpoint) {
        .footer-wrapper {
            padding-left: 10%;
            padding-right: 10%;
            flex-direction: column;
        }
    }

    .main-links {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .footer-heading {
        margin-bottom: rem-calc(10);
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.8);
        font-weight: bold;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        a {
            display: block;
            color: rgba(255, 255, 255, 0.4);
            font-size: rem-calc(14);
            padding: rem-calc(4 0);
            &:HOVER, &:FOCUS {
                color: #FFFFFF;
            }
        }
    }
}

#copyright {
    color: rgba(255, 255, 255, 0.4);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: rem-calc(30);
    font-size: rem-calc(14);
}
