@charset "UTF-8";
@import "settings_override";
@import "../../bower_components/foundation/scss/foundation/_settings.scss";
@import "../../bower_components/foundation/scss/foundation.scss";
@import "cw-styles";
@import "modules/nav";
@import "modules/forms";
@import "modules/footer";

.overview-header {
    position: relative;
    min-height: 400px;
    max-height: 800px;
}

.header-v-50 {
    @extend .overview-header;
    height: 50vh;
}

.header-v-100 {
    @extend .overview-header;
    height: 100vh;
}

.overview-bg-home {
    background-image: url("../images/main.jpg");
}

.overview-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
}

.overview-main h1 {
    font-size: rem-calc(36);
    font-weight: bold;
    border: 0;
    margin: 0;
    color: $white;
    padding: 0 em-calc(20);
    word-spacing: 2px;
}

@media (max-width: $medium-breakpoint) {
    .overview-main h1 {
        font-size: rem-calc(32);
    }

}

@media (max-width: $small-breakpoint) {
    .overview-main h1 {
        font-size: rem-calc(26);
        font-weight: bold;
    }
}

.overview-main h3 {
    color: $white;
}

.feature-grid h4 {
    font-weight: bold;
}

#solutions h4 {
    font-weight: bold;
}

.overview-main .request-invite {
    margin-left: 0;
    margin-top: rem-calc(30);
    padding: rem-calc(12 30);
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}

@media (max-width: $medium-breakpoint) {
    .overview-main .request-invite {
        margin-top: rem-calc(24);
        padding: rem-calc(24 24);
        height: rem-calc(40);
        line-height: 0;
    }
}

.btn-play {
    background: rgba(0, 0, 0, 0.05);
    border: 2px solid black;
    color: black;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    font-size: 2.6rem;
    padding-left: 20px;
    margin-top: 14px;
    flex: 0 0 auto;
    transition: all 150ms ease 0s;
}

.btn-play:HOVER {
    background: $primary-color;
    color: white;
    border: 2px solid transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.btn-play:FOCUS {
    outline: none;
    background: $primary-color;
    border: 2px solid transparent;
}

.btn-play-label {
    color: black;
    font-style: italic;
    font-weight: bold;
    margin-top: 8px;
}

.main-heading {
    color: #FFFFFF;
    margin: 0;
    padding: 30px 100px;
    text-align: center;
    overflow: hidden;
    font-size: rem-calc(20);
    background-image: url("../images/triangles.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

@media (max-width: $small-breakpoint) {
    .main-heading {
        font-size: rem-calc(18);
        background-attachment: initial;
        background-size: 160%;
        padding-left: 20px;
        padding-right: 20px;
    }
}

#overview article .left {
    width: 50%;
}

#features .left {
    width: 65%;
}

#features .row {
    display: flex;
}

@media (max-width: $medium-breakpoint) {
    #features .row {
        display: block;
    }
}

#solutions-page-image-wrapper {
    min-height: 1px;
}

#solutions-page-image {
    max-width: none;
    height: 100%;
}

@media (max-width: $medium-breakpoint) {
    #solutions-page-image {
        position: static;
        margin-top: 0;
        width: 100%;
    }
}

.right {
    width: 65%;
    float: right;
}

.solution-link {
    display: block;
    padding: 20px;
    border-radius: $global-radius;
    &:HOVER, &:FOCUS {
        text-decoration: none;
        background: $white-smoke;
    }
}

@media (min-width: $small-breakpoint) {
    .learn-more {
        display: none;
    }
}

@media (max-width: $small-breakpoint) {
    .overview-main .request-invite {
        font-size: rem-calc(14);
    }

    .large-4.medium-4.columns {
        margin-bottom: 20px;

        &:first-child {
            margin-top: 10px;
        }
    }

    .large-3.medium-3.columns {
        p {
            margin-bottom: 10px;
        }
    }

    .solution-link {
        p {
            margin-bottom: 10px;
        }

        h4 {
            font-weight: bold;
        }
    }

    .learn-more {
        a {
            text-decoration: none;
        }
    }

    #benefits h3 {
        font-weight: bold;
    }

    #security h3 {
        font-weight: bold;
    }
}

.pricing-org-lbl {
    font-weight: bold;
    font-size: rem-calc(18px);
    margin-bottom: 6px;
}

#pricing-table {
    display: table;
    width: 100%;
    table-layout: fixed;
    overflow: hidden;
    margin: auto;
    border: 0;
    max-width: 800px;
}

#pricing-table td {
    padding: 20px;
}

#disclaimer {
    margin-top: rem-calc(14);
}

#conditions {
    font-size: rem-calc(11);
}

#conditions div {
    margin-top: rem-calc(8);
}

#pricing-table tr {
    background: none;
}

#pricing-table tr:first-child td {
    background: $secondary-color;
    color: white;
    font-size: rem-calc(18);
}

.country-name {
      color: #000000;
      font-style: italic;
      font-size: rem-calc(20);
}

#pricing-table tr:first-child td:first-child {
    background: none;
    text-align: left;
    padding-left: 20px;
}

#pricing-table td:first-child {
    color: #000000;
    text-align: right;
    padding-left: 100px;
}

#pricing-table td:not(:first-child) {
    text-align: center;
}

#pricing-table td + td {
    border-left: 3px solid white;
    background: $white-smoke;
}

#pricing-wrapper .card {
    margin-top: rem-calc(40);
}

.card {
    border-radius: 3px;
    padding: rem-calc(30);
    margin: rem-calc(14) 0 rem-calc(24);
    box-shadow: 0 3px 10px -1px rgba(0,0,0,0.16);
    background: $ghost;

	&.important-callout {
		margin-left: auto;
		margin-right: auto;
	}
}

.card .key {
    padding-right: rem-calc(20);
    width: rem-calc(100);
    color: $aluminum;
}

.card .value {
    word-break: break-all;
}

.card .value + .key {
    margin-top: 14px;
}

@media (max-width: $small-breakpoint) {
    #pricing-table td:first-child{
        padding-left: 0px;
    }

    #pricing-table td {
        padding: 8px;
    }

    .card .key {
        padding-right: rem-calc(14);
        width: rem-calc(80);
    }
}

@media (max-width: $medium-breakpoint) {
    #pricing-table td {
        padding: 14px;
    }

    .card {
        padding: rem-calc(20);
    }
}

