// ICONS
.feature-icon {
    width: 60px;
    height: 60px;
    margin: 0 auto rem-calc(20) auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60px;
}

.icon-working-papers {
    background-image: url("../images/icons/working-papers.svg");
}

.icon-working-papers-sync {
    background-image: url("../images/icons/working-papers-sync.svg");
}

.icon-practice-management-solution {
    background-image: url("../images/icons/practice-management.svg");
}

.icon-person {
    background-image: url("../images/icons/person.svg");
}

.icon-people {
    background-image: url("../images/icons/app-people.svg");
}

.icon-files {
    background-image: url("../images/icons/app-files.svg");
}

.icon-lock {
    background-image: url("../images/icons/lock.svg");
}

.icon-overview {
    background-image: url("../images/icons/app-overview.svg");
}

.icon-entities {
    background-image: url("../images/icons/app-entities.svg");
}

.icon-q {
    background-image: url("../images/icons/app-q.svg");
}

.icon-association {
    background-image: url("../images/icons/association.svg");
}

.icon-folder {
    background-image: url("../images/icons/folder.svg");
}

.icon-time {
    background-image: url("../images/icons/app-time.svg");
}

.icon-success {
    background-image: url("../images/icons/success.svg");
}
