.columns {
    padding-left: 1%;
    padding-right: 1%;
    &:FIRST-CHILD {
        padding-left: 0;
    }

    &:LAST-CHILD {
        padding-right: 0;
    }
}

@media (max-width: $medium-breakpoint) {
    .columns {
        padding: 0;
        margin-bottom: rem-calc(14);
        &:LAST-CHILD {
            margin-bottom: 0;
        }
    }
}

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], input[type="checkbox"], input[type="radio"], textarea, select {
    font-weight: normal;
    margin: 0;
    color: $steel;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
}

.error input[type="checkbox"] {
    margin: 0;
}

select {
    transition: $input-transition-fade-time;
}

select:HOVER {
    cursor: pointer;
}

select:FOCUS {
    border-color: $secondary-color;
    outline: none;
}

.select-with-heading-centered {
    width: 50%;
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: auto;
}

@media (max-width: $medium-breakpoint) {
    .select-with-heading-centered {
        width: 70%;
    }
}

@media (max-width: $small-breakpoint) {
    .select-with-heading-centered {
        width: 100%;
    }
}

input[type=submit] {
    font-weight: bold;
    line-height: 20px;
    height: 40px;
    padding: 0 20px;
    transition: all 150ms ease 0s;
    opacity: 0.9;
    color: $white;
    border: 0;
    border-radius: 3px;
    background: #57A8EA;
    &:HOVER, &:FOCUS {
        cursor: pointer;
        opacity: 1;
        outline: none;
    }
}

input[type=checkbox] + label, input[type=radio] + label {
    font-weight: normal;
}

.label-note {
    font-weight: normal;
    font-style: italic;
    color: #999999;
    margin-left: rem-calc(6);
}

fieldset legend {
    font-size: rem-calc(18);
}

small.error {
    border-radius: 2px;
}

span.prefix-login-address {
    font-size: rem-calc(16);
    text-align: left;
    color: $jumbo;
    border: 0;
    background: none;
}

.textarea-message, .textarea-address {
    line-height: 1.5;
    min-height: 82px;
}

.section-heading {
    font-size: rem-calc(16);
    margin: rem-calc(10) 0 rem-calc(14);
    padding: rem-calc(8) 0;
    border-bottom: 2px solid $secondary-color;
    color: $secondary-color;
    font-weight: bold;
}

#working-papers-versions-row, #time-integration-row {
    display: none;
}

form section + section {
    margin-top: rem-calc(30);
    padding-top: 0;
}

form .row {
    margin-bottom: rem-calc(14);
}

$spacing-unit: 16px;
$half-spacing-unit: $spacing-unit / 2;
$quarter-spacing-unit: $spacing-unit / 4;

input[type=radio], input[type=checkbox] {
      // not display:none, else abide doesnt work
      visibility: hidden;
      width: 0;
}

input[type=radio] + label {
    display: inline-block;
    position: relative;
    margin-left: 0 !important;
    margin-right: $spacing-unit;
    padding-left: $spacing-unit + $half-spacing-unit;
    cursor: pointer;

    // this creates the outer circle for faux radio button
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: 50%;
        margin-top: -8px;
        border: 1px solid $gainsboro;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
    }

    // this creates the inner circle for active faux radio button
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 8px;
        margin-top: 0;
        background: $primary-color;
        border-radius: 4px;
        transition: 0.2s ease-in-out;
    }
}

input[type=radio]:checked + label {
    &:after {
        height: 8px;
        width: 8px;
        margin-top: -4px;
        left: 4px;
    }
}

input[type=checkbox] + label {
    display: inline-block;
    position: relative;
    margin-left: 0 !important;
    margin-right: $spacing-unit;
    padding-left: $spacing-unit + $half-spacing-unit;
    cursor: pointer;

    // this creates the outer square for faux checkbox button
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: 50%;
        margin-top: -8px;
        border: 1px solid $gainsboro;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
    }
    // this creates the inner square for active faux checkbox button
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 8px;
        margin-top: 0;
        background: $primary-color;
        transition: 0.2s ease-in-out;
    }
}
input[type=checkbox]:checked + label {
    &:after {
        height: 8px;
        width: 8px;
        margin-top: -4px;
        left: 4px;
    }
}

// error style that won't be touched by abide
small.error-msg {
   display: block;
   font-size: 0.75rem;
   font-style: italic;
   font-weight: normal;
   margin-bottom: 1rem;
   margin-top: 8px;
   padding: 0.375rem 0.625rem;
   background: $alert-color;
   color: $white;
}

#data-center {
    background-color: #4caf50;
    padding-left: 1rem;
    color: white;
}
