@import "settings_override";

// GENERAL HEADER STYLES
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $nav-height;
    z-index: 100;
    padding: 0 rem-calc(20) 0 rem-calc(16);
    border-bottom: 1px solid transparent;
    transition: all 200ms ease 0s;
}

@media (max-width: $mid-small-breakpoint) {
    header {
        height: $nav-small-height;
        padding: 0 rem-calc(10) 0 rem-calc(6);
        position: absolute;
        box-shadow: none;

        .logo-anchor {
            margin: auto;

            h3 {
                font-size: rem-calc(24);
            }
        }

        a.request-invite {
            display: none;
        }
    }
}

.header-dark {
    background: rgba(0, 0, 0, 0.4);
    border-color: rgba(0, 0, 0, 0.15);
}

.header-dark-blue {
    @extend .header-dark;
    background: rgba(6, 55, 75, 0.65);
}

.header-dark-transparent {
    background: transparent;
    border-color: transparent;
}

.header-light {
    background: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid #DDDDDD;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
}

@media (max-width: $small-breakpoint) {
    .header-light {
        box-shadow: none;
    }
}

.content-fade:AFTER {
    left: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
    width: 100%;
    content: "";
    position: fixed;
    z-index: 10;
    right: 0;
    top: 0;
    height: $nav-height;
}


// LOGO
.logo-anchor {
    padding: 4px 12px;
    transition: all 150ms ease 0s;
    border-radius: $global-radius;
    &:HOVER, &:FOCUS {
        text-decoration: none;
    }

    &:FOCUS {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3) inset;
    }
}

.header-light .logo-anchor:FOCUS {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
}

.logo {
    color: $tuatara;
    letter-spacing: -0.6px;
    font-size: rem-calc(24);
    white-space: nowrap;
    margin: 0;
}

.logo .online {
    color: #58BBFF;
    margin-left: 8px;
}

@media (max-width: $medium-breakpoint) {
    .logo {
        font-size: rem-calc(22);
    }
}

@media (max-width: $small-breakpoint) {
    .logo {
        font-size: rem-calc(20);
        margin: 0;
    }
}

.header-dark .logo {
    color: $white;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}


// NAVUGATION
nav {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        display: block;
        text-decoration: none;
        color: black;
        border-radius: 3px;
        position: relative;
        overflow: hidden;
    }

    a:HOVER {
        background: rgba(0, 0, 0, 0.1);
        color: black;
        outline: none;
        cursor: pointer;
        text-decoration: none;
    }

    button {
        margin-left: rem-calc(14);
    }

    > ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 10px;
        ul {
            display: none;
        }

        li:HOVER {
            ul {
                display: block;
                position: absolute;
                background: white;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.08);
                padding: 14px 0;
                border-radius: 2px;
                margin-left: 0;
                a {
                    border-radius: 0;
                    height: 34px;
                    line-height: 34px;
                    padding: 0 20px;
                }

                a:HOVER {
                    background: $primary-color;
                    color: white;
                }
            }
        }
    }

    .main-link {
        height: 40px;
        line-height: 40px;
        margin-bottom: 10px;
        padding: 0 20px;
        font-weight: bold;
        letter-spacing: -0.2px;
        transition: background 200ms ease 0s;
        &:HOVER, &:FOCUS {
            text-decoration: none;
            outline: none;
        }
    }

    @media (max-width: $medium-breakpoint) {
        .main-link {
            font-size: rem-calc(12);
            padding: 0 8px;
            height: 28px;
            line-height: 28px;
        }
    }

    @media (max-width: $mid-small-breakpoint) {
        .main-link {
            display: none;
        }
    }
}

.header-dark .main-link {
    color: $white;
    &:HOVER {
        background: rgba(255, 255, 255, 0.2);
    }

    &:FOCUS {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3) inset;
    }
}

header a.request-invite {
    margin-left: rem-calc(14);
    padding: rem-calc(6 14);
    font-size: rem-calc(14);
    white-space: nowrap;
    color: $white;
    border-color: $red;
    background: $red;
    &:HOVER:enabled {
        text-decoration: none;
        background: darken($red, 3%);
     }

    &:FOCUS {
        background: darken($red, 3%);
    }
}
