@import "settings_override";
@import "modules/icons";

// TOP LEVEL DOM ELEMENTS
html, body {
    max-width: 100%;
    min-height: 100%;
    height: 100%;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 200ms ease 0s;
    background: #FFFFFF;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

main > section:LAST-CHILD {
    padding-bottom: rem-calc(100);
}

@media (max-width: $medium-breakpoint) {
    main > section:LAST-CHILD {
        padding-bottom: rem-calc(80);
    }
}

@media (max-width: $small-breakpoint) {
    main > section:LAST-CHILD {
        padding-bottom: rem-calc(60);
    }
}

section {
    width: 100%;
    flex: 0 0 auto;
}

section + section {
    padding-top: rem-calc(60);
}

@media (max-width: $medium-breakpoint) {
    section + section {
        padding-top: rem-calc(30);
    }
}

@media (max-width: $small-breakpoint) {
    section + section {
        padding-top: 0;
    }
}

section > article {
    padding: rem-calc(0 60);
    transition: all 100ms ease 0s;
}

@media (max-width: $medium-breakpoint) {
    section > article {
        padding: rem-calc(40);
    }
}

@media (max-width: $small-breakpoint) {
    section > article {
        padding: rem-calc(30) rem-calc(30) 0;
    }
}

section h1 {
    font-size: rem-calc(26);
    max-width: rem-calc(1000);
    margin-right: auto;
    margin-bottom: rem-calc(40);
    margin-left: auto;
    padding-bottom: rem-calc(14);
    padding-left: 0;
    text-transform: uppercase;
    border-bottom: 2px solid $dark-smoke;
}

@media (max-width: $medium-breakpoint) {
    section h1 {
        font-size: rem-calc(28);
    }
}

@media (max-width: $small-breakpoint) {
    section h1 {
        font-size: rem-calc(26);
    }
}

section p {
    color: #666666;
}

ul {
    list-style: none;
}

.article-content {
    padding: 0 rem-calc(40);

    .row + .row {
        margin-top: rem-calc(20);
    }
}

@media (max-width: $medium-breakpoint) {
    .article-content {
        padding: 0 rem-calc(20);
    }
}

@media (max-width: $small-breakpoint) {
    .article-content {
        padding: 0;
    }
}


// TYPOGRAPHY
h1 {
    font-weight: normal;
    color: black;
}

h2 {
    font-weight: normal;
}

h3 {
    font-weight: normal;
    color: black;
}

small {
    font-size: 70%;
    display: inline-block;
    letter-spacing: 0.2px;
}


// BUTTONS
button {
    margin: 0;
    padding: 8px 14px;
    border: 0;
    border-radius: 2px;
}

.button-large {
    font-weight: bold;
    padding: rem-calc(14 30);
    transition: all 150ms ease 0s;
    color: $white;
    border-radius: 3px;
    background: linear-gradient(to bottom, $primary-color, $primary-color);
    &:HOVER:enabled {
        background: linear-gradient(to bottom, $primary-color, $primary-color);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }

    &:FOCUS {
        outline: none;
        background: linear-gradient(to bottom, $secondary-color, $primary-color);
    }
}

.button-hollow {
    color: $secondary-color;
    border: 2px solid $secondary-color;
    background: transparent;

    @extend .button-large;
    opacity: 1;
    &:HOVER:enabled {
        color: white;
        border-color: transparent;
        background: $secondary-color;
    }

    &:FOCUS {
        color: white;
        border-color: transparent;
        background: $secondary-color;
    }
}

.show-pricing {
    padding: 14px 20px;
}

// ANCHORS
a, a:HOVER a:VISITED {
    color: $primary-color;
    &:HOVER {
        cursor: pointer;
        text-decoration: underline;
    }

    &:FOCUS {
        text-decoration: underline;
        outline: none;
    }
}

a.request-invite {
    @extend .button-large;
    white-space: nowrap;
    color: $white;
    border-color: $red;
    background: $red;

    &:HOVER:enabled {
        text-decoration: none;
        background: darken($red, 3%);
    }

    &:FOCUS {
        background: darken($red, 3%);
    }
}

@media (max-width: $medium-breakpoint) {
    a.request-invite {
        font-size: rem-calc(14);
        margin-left: rem-calc(10);
        padding: rem-calc(8 14);
    }
}

@media (max-width: $small-breakpoint) {
    a.request-invite {
        font-size: rem-calc(12);
        padding: rem-calc(6 12);
    }
}

// DIALOGS
.reveal-modal:FOCUS {
    outline: none;
}

@media (max-width: $medium-breakpoint) {
    .reveal-modal.medium {
        right: 0;
        left: 0;
        width: 90%;
        margin: 0 auto;
    }
}

@media (max-width: $small-breakpoint) {
    .reveal-modal.medium {
        width: 96%;
    }
}

.dialog-title {
    font-weight: bold;
    margin-bottom: rem-calc(20);
    color: steelblue;
}


// GENERAL
.flex {
    display: flex;
}

.flex-align-center {
    align-items: center;
}

.flex-align-baseline {
    align-items: baseline;
}

.flex-align-strech {
    align-items: stretch;
}

.flex-justify-center {
    justify-content: center;
}

.flex-0 {
    flex: 0 0 auto;
}

.flex-1 {
    flex: 1 1 auto;
}

input[type=checkbox] + label {
    line-height: 1.5;
    margin-left: 10px;
}

table {
    border-collapse: collapse;
}

.important-callout {
    margin-bottom: rem-calc(50);
    padding: rem-calc(30);
    border-radius: 2px;
}

@media (max-width: $medium-breakpoint) {
    .important-callout {
        margin-bottom: rem-calc(30);
        padding: rem-calc(20);
    }
}

.no-margin-bottom {
    margin-bottom: 0;
}

.italic-link {
    font-style: italic;
    text-decoration: underline;
    color: $primary-color;
}

.feature-grid {
    margin-top: rem-calc(80);
}

@media (max-width: $medium-breakpoint) {
    .feature-grid {
        margin-top: rem-calc(20);
    }
}

@media (max-width: $small-breakpoint) {
    .feature-grid {
        margin-top: 0;
    }
}

.feature-grid .row + .row {
    margin-top: rem-calc(40);
}

@media (max-width: $medium-breakpoint) {
    .feature-grid .row + .row {
        margin-top: rem-calc(0);
    }
}

.feature-grid p {
    padding: 0 10px;
}

.overview-bg {
    min-height: 100%;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &:AFTER {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: rgba(0, 0, 0, 0.7);
    }
}
